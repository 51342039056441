<template>
	<div>
		
		<div class="">
			<a-button type="primary" icon="plus" @click="addAccountAct()">添加账号</a-button>
		</div>
		<div >
			<div class="form-search-box mt20">
				<a-form layout="inline">
					<a-form-item label="帐号">
						<a-input v-model="search.username" placeholder="请输入帐号名称"></a-input>
					</a-form-item>

					<a-form-item label="手机号">
						<a-input v-model="search.mobile" placeholder="请输入手机号"></a-input>
					</a-form-item>

					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
				</a-form>
			</div>
		</div>

		<div class="mt20">
			<div class="wxb-table-white">
				<a-table rowKey="account_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
				 :loading="loading">
					
					
					<div class="flex alcenter center cl-theme" slot="create_shop_count" slot-scope="create_shop_count,record">
						{{create_shop_count}}个
					</div>
					
					<div class="flex alcenter center cl-theme clickAct" slot="manage_shop_count" slot-scope="manage_shop_count,record" >
						{{manage_shop_count}}个
					</div>


					<template slot="action" slot-scope="text,record">
						<div class="flex center">
							<a-dropdown placement="bottomRight">
								<span class="more-act">
									<i class="iconfont iconmore_gray"></i>
								</span>
								<a-menu slot="overlay">
									<a-menu-item>
										<a class="menu-act" href="javascript:;" @click="seeShopAct(record)">
											<i class="iconfont ft14 iconshop_operation01"></i>
											<span class="ml10">查看商铺</span>
										</a>
									</a-menu-item>
								</a-menu>
							</a-dropdown>
						</div>
					</template>
				</a-table>
			</div>
			
			<div v-if="seeShopVisible">
				<see-shop :visible="seeShopVisible" :account_id="account_id" @cancel="cancelSeeShop"></see-shop>
			</div>
			
			<div v-if="addAccountVisible">
				<add-account :visible="addAccountVisible" @cancel="cancelAddAccount" @ok="okAddAccount"></add-account>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../common/mixin/list.js';
	import seeShop from './components/index/modal/seeShop.vue';
	import addAccount from './components/index/modal/addAccount.vue';
	export default {
		mixins: [listMixin],
		components:{
			seeShop,
			addAccount,
		},
		data() {
			return {
				loading: false,
				seeShopVisible:false,
				addAccountVisible:false,
				account_id:0,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				search: {
					username: '',
					mobile: "",
				},
				columns: [
					{title: '编号',dataIndex: 'account_id',align: 'center',ellipsis: true},
					{title: '帐号名称',dataIndex: 'username',align: 'center',ellipsis: true},
					{title: '手机号',dataIndex: 'mobile',align: 'center',ellipsis: true},
					{title: '开通时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					{title: '最后活跃时间',dataIndex: 'last_login_time_format',align: 'center',ellipsis: true},
					{title: '创建小程序',dataIndex: 'create_shop_count',align: 'center',scopedSlots: {customRender: 'create_shop_count'}},
					{title: '可管理小程序',dataIndex: 'manage_shop_count',align: 'center',scopedSlots: {customRender: 'manage_shop_count'}},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}},
					
				],
				datas: [],
			}
		},
		created() {
			
		},
		methods: {
			
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/admin/getAccountList',{
					keyword:this.search.keyword,
					username:this.search.username,
					mobile:this.search.mobile,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
				}).catch(res=>{
					console.log(res);
				}).finally(()=>{
					this.loading=false;
				})
				
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			
			//查看商铺
			seeShopAct(record){
				this.account_id=record.account_id;
				this.seeShopVisible=true;
			},
			cancelSeeShop(){
				this.seeShopVisible=false;
			},
			
			//添加账号
			addAccountAct(){
				this.addAccountVisible=true;
			},
			cancelAddAccount(){
				this.addAccountVisible=false;
			},
			okAddAccount(){
				this.addAccountVisible=false;
				this.getLists();
			}
		}
	}
</script>

<style>
</style>
