<template>
	<div>
		<a-modal title="查看商铺" :width="700" :visible="visible"  @cancel="handleCancel" :footer="null">
			<div>
				<div class="wxb-table-gray">
					<a-table rowKey="shop_account_id" :columns="columns"  :data-source="datas" :loading="loading">
						<div slot="name" slot-scope="text,record">
							{{record.shop.name}}
						</div>
						
						<div slot="app" slot-scope="text,record">
							{{record.shop.app.name}}
						</div>
						
						<div slot="expire_time_format" slot-scope="text,record">
							{{record.shop.expire_time_format}}
						</div>
						
						<template slot="action" slot-scope="text,record">
							<a-button type="primary" @click="manageAct(record)">进入管理</a-button>
						</template>
					</a-table>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	// import {listMixin} from '../../../../../common/mixin/list.js';
	export default {
		// mixins:[listMixin],
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
			account_id:{
				type:Number,
				default:0,
			},
		},
		data() {
			return {
				loading: false,
				columns: [
					{title: '商品名称',key: 'name',align: 'center',scopedSlots: {customRender: 'name'}},
					{title: '应用分类',key: 'app',align: 'center',scopedSlots: {customRender: 'app'}},
					{title: '有效期',key: 'expire_time_format',align: 'center',scopedSlots: {customRender: 'expire_time_format'}},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}},
					
				],
				datas: [],
			}
		},
		created() {
			this.getLists();
		},
		methods: {
			
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/admin/getAccountShopList',{
					account_id:this.account_id,
				}).then(res=>{
					this.datas=res.datas;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.$emit('cancel');
				}).finally(()=>{
					this.loading=false;
				})
			},
			
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
			
			manageAct(record){
				if(record.shop == null){
					this.$message.error('该门店已注销');
					return false;
				}
				let base_url=record.shop.app.development_address;
				window.open(base_url  + 'auth?shop_token='+ encodeURIComponent(record.shop_token));
			},
			
		}
	}
</script>

<style>
</style>
